import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"8"}},[_c(VCard,{staticStyle:{"overflow-y":"auto"},attrs:{"flat":"","outlined":"","shaped":"","loading":_vm.loading,"height":_vm.cardHeight,"max-height":_vm.cardHeight}},[_c(VCardTitle,[_vm._v(" PRIVATE CATALOG ")]),_c('CatalogItemTable',{attrs:{"tableHeight":_vm.tableHeight}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }