<template>
	<v-container fluid class="pa-0" v-resize="onResize">
		<v-row no-gutters justify="center">
			<v-col cols="12" lg="8">
				<v-card
					flat
					outlined
					shaped
					:loading="loading"
					:height="cardHeight"
					:max-height="cardHeight"
					style="overflow-y: auto"
				>
					<v-card-title> PRIVATE CATALOG </v-card-title>

					<CatalogItemTable :tableHeight="tableHeight" />
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	import { mapGetters } from "vuex";
	import CatalogItemTable from "@/components/CatalogItemTable";

	export default {
		name: "PrivateCatalog",
		components: { CatalogItemTable },
		data() {
			return {
				loading: false,
				tableHeight: null,
				cardHeight: null,
			};
		},
		created() {
			this.onResize;
			this.$store.dispatch("products/fetchPrivateCatalog");
			if (!this.readOnly) {
				this.$store.dispatch("shoppingCart/fetchShoppingCart");
			}
		},
		computed: {
			...mapGetters({
				readOnly: "session/readOnly",
			}),
		},
		methods: {
			onResize() {
				this.cardHeight =
					window.innerHeight -
					this.$vuetify.application.top -
					this.$vuetify.application.footer -
					2;
				this.tableHeight = this.cardHeight;
				this.tableHeight -= 72; // card-title
				this.tableHeight -= 56; // pagination
			},
		},
	};
</script>
